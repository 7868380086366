import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { useEventStore } from '../../store/eventStore';
import { useDJStore } from '../../store/djStore';
import { Event, DJ } from '../../types';
import { getRandomEventImage } from '../../utils/imageUtils';
import TimeSlotEditor from '../../components/TimeSlotEditor';
import TimelineView from '../../components/TimelineView';
import ImageUpload from '../../components/ImageUpload';

export default function EventEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { events, loading: eventLoading, fetchEvents, addEvent, updateEvent } = useEventStore();
  const { djs, loading: djLoading, fetchDJs } = useDJStore();
  const event = events.find(e => e.id === id);
  const [imagePreview, setImagePreview] = useState<string | undefined>(event?.image);
  const [selectedDJs, setSelectedDJs] = useState<string[]>(event?.djs || []);
  const [timeSlots, setTimeSlots] = useState(event?.timeSlots || []);

  // Split date and time for form handling
  const defaultDate = event?.date ? format(parseISO(event.date), 'yyyy-MM-dd') : '';
  const defaultTime = event?.date ? format(parseISO(event.date), 'HH:mm') : '';

  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm<Event & { startTime: string }>({
    defaultValues: {
      name: event?.name || '',
      date: defaultDate,
      startTime: defaultTime,
      duration: event?.duration || 4,
      location: event?.location || '',
      image: event?.image || '',
      ticketed: event?.ticketed || false,
      price: event?.price,
      description: event?.description || '',
      isSecretLocation: event?.isSecretLocation || false,
      rsvpOptions: event?.rsvpOptions || {
        requireSMS: false,
        requireCaptcha: false
      },
      djs: event?.djs || [],
      timeSlots: event?.timeSlots || []
    }
  });

  // Reset form when event data is loaded
  useEffect(() => {
    if (event) {
      const date = format(parseISO(event.date), 'yyyy-MM-dd');
      const time = format(parseISO(event.date), 'HH:mm');
      
      reset({
        ...event,
        date,
        startTime: time,
      });
      
      setSelectedDJs(event.djs);
      setTimeSlots(event.timeSlots);
      setImagePreview(event.image);
    }
  }, [event, reset]);

  const isSecretLocation = watch('isSecretLocation');
  const requireSMS = watch('rsvpOptions.requireSMS');
  const eventDate = watch('date');
  const startTime = watch('startTime');
  const duration = watch('duration');

  useEffect(() => {
    Promise.all([
      fetchEvents(),
      fetchDJs()
    ]).catch(err => setError(err.message));
  }, [fetchEvents, fetchDJs]);

  const handleRandomImage = () => {
    const imageUrl = getRandomEventImage();
    setImagePreview(imageUrl);
    setValue('image', imageUrl);
  };

  const onSubmit = async (data: Omit<Event, 'id'> & { startTime: string }) => {
    try {
      setError(null);
      
      // Create a date object in local timezone
      const [year, month, day] = data.date.split('-').map(Number);
      const [hours, minutes] = data.startTime.split(':').map(Number);
      const eventDate = new Date(year, month - 1, day, hours, minutes);
      
      // Format as ISO string and keep only the date and time part
      const combinedDate = eventDate.toISOString().split('.')[0];
      
      const formattedData = {
        ...data,
        date: combinedDate,
        djs: selectedDJs,
        timeSlots
      };

      if (id) {
        await updateEvent(id, formattedData);
      } else {
        await addEvent(formattedData);
      }
      navigate('/admin/events');
    } catch (err) {
      setError((err as Error).message);
    }
  };

  // Get the selected DJs' full information
  const selectedDJsData = djs.filter(dj => selectedDJs.includes(dj.id));

  if (eventLoading || djLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-white">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white mb-8">
          {id ? 'Edit Event' : 'Create New Event'}
        </h1>

        {error && (
          <div className="mb-6 p-4 bg-red-500/20 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-white mb-2">Event Name</label>
            <input
              {...register('name', { required: 'Name is required' })}
              className="input bg-white/10 text-white border-gray-700"
            />
            {errors.name && (
              <span className="text-red-400 text-sm mt-1">{errors.name.message}</span>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-white mb-2">Event Date</label>
              <input
                type="date"
                {...register('date', { required: 'Date is required' })}
                className="input bg-white/10 text-white border-gray-700"
              />
              {errors.date && (
                <span className="text-red-400 text-sm mt-1">{errors.date.message}</span>
              )}
            </div>

            <div>
              <label className="block text-white mb-2">Start Time</label>
              <input
                type="time"
                {...register('startTime', { required: 'Start time is required' })}
                className="input bg-white/10 text-white border-gray-700"
              />
              {errors.startTime && (
                <span className="text-red-400 text-sm mt-1">{errors.startTime.message}</span>
              )}
            </div>
          </div>

          <div>
            <label className="block text-white mb-2">Event Duration (hours)</label>
            <input
              type="number"
              min="1"
              max="24"
              {...register('duration', { 
                required: 'Duration is required',
                min: { value: 1, message: 'Duration must be at least 1 hour' },
                max: { value: 24, message: 'Duration cannot exceed 24 hours' }
              })}
              className="input bg-white/10 text-white border-gray-700"
            />
            {errors.duration && (
              <span className="text-red-400 text-sm mt-1">{errors.duration.message}</span>
            )}
          </div>

          <div>
            <label className="block text-white mb-2">Location</label>
            <input
              {...register('location', { required: 'Location is required' })}
              className="input bg-white/10 text-white border-gray-700"
            />
            {errors.location && (
              <span className="text-red-400 text-sm mt-1">{errors.location.message}</span>
            )}
          </div>

          <div>
            <label className="block text-white mb-2">Event Image</label>
            <div className="space-y-4">
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Event preview"
                  className="w-full h-48 object-cover rounded-lg"
                />
              )}
              <div className="grid grid-cols-2 gap-4">
                <ImageUpload
                  onUpload={(url) => {
                    setValue('image', url);
                    setImagePreview(url);
                  }}
                  defaultImage={imagePreview}
                  folder="event-covers"
                />
                <button
                  type="button"
                  onClick={handleRandomImage}
                  className="btn btn-primary h-full"
                >
                  Use Random Stock Image
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('ticketed')}
              className="rounded border-gray-700 bg-white/10"
            />
            <label className="text-white">Ticketed Event</label>
          </div>

          {watch('ticketed') && (
            <div>
              <label className="block text-white mb-2">Ticket Price ($)</label>
              <input
                type="number"
                step="0.01"
                min="0"
                {...register('price', { 
                  required: 'Price is required for ticketed events',
                  min: { value: 0, message: 'Price cannot be negative' }
                })}
                className="input bg-white/10 text-white border-gray-700"
              />
              {errors.price && (
                <span className="text-red-400 text-sm mt-1">{errors.price.message}</span>
              )}
            </div>
          )}

          <div>
            <label className="block text-white mb-2">Description</label>
            <textarea
              {...register('description', { required: 'Description is required' })}
              className="input bg-white/10 text-white border-gray-700"
              rows={4}
            />
            {errors.description && (
              <span className="text-red-400 text-sm mt-1">{errors.description.message}</span>
            )}
          </div>

          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                {...register('isSecretLocation')}
                className="rounded border-gray-700 bg-white/10"
              />
              <label className="text-white">Secret Location (Requires RSVP)</label>
            </div>

            {isSecretLocation && (
              <div className="pl-6 space-y-4">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register('rsvpOptions.requireSMS')}
                    className="rounded border-gray-700 bg-white/10"
                  />
                  <label className="text-white">Require SMS Verification</label>
                </div>

                {requireSMS && (
                  <div className="pl-6">
                    <label className="block text-white mb-2">SMS Contact Number</label>
                    <input
                      {...register('rsvpOptions.smsNumber', {
                        required: 'SMS number is required when SMS verification is enabled'
                      })}
                      placeholder="+1234567890"
                      className="input bg-white/10 text-white border-gray-700"
                    />
                  </div>
                )}

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register('rsvpOptions.requireCaptcha')}
                    className="rounded border-gray-700 bg-white/10"
                  />
                  <label className="text-white">Require Captcha Verification</label>
                </div>
              </div>
            )}
          </div>

          <div>
            <label className="block text-white mb-2">Select DJs</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {djs.map(dj => (
                <label key={dj.id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedDJs.includes(dj.id)}
                    onChange={(e) => {
                      const newSelectedDJs = e.target.checked
                        ? [...selectedDJs, dj.id]
                        : selectedDJs.filter(id => id !== dj.id);
                      setSelectedDJs(newSelectedDJs);
                      
                      // Update time slots when DJs change
                      if (e.target.checked) {
                        setTimeSlots([...timeSlots, {
                          djId: dj.id,
                          startTime: startTime || '00:00',
                          endTime: startTime || '00:00'
                        }]);
                      } else {
                        setTimeSlots(timeSlots.filter(slot => slot.djId !== dj.id));
                      }
                    }}
                    className="rounded border-gray-700 bg-white/10"
                  />
                  <span className="text-white">{dj.name}</span>
                </label>
              ))}
            </div>
          </div>

          {selectedDJs.length > 0 && eventDate && startTime && (
            <>
              <div>
                <label className="block text-white mb-4">Set Performance Times</label>
                <TimeSlotEditor
                  eventDate={`${eventDate}T${startTime}`}
                  duration={duration}
                  selectedDJs={selectedDJsData}
                  timeSlots={timeSlots}
                  onChange={setTimeSlots}
                />
              </div>

              <div>
                <label className="block text-white mb-4">Timeline Preview</label>
                <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
                  <TimelineView
                    eventDate={`${eventDate}T${startTime}`}
                    duration={duration}
                    timeSlots={timeSlots}
                    djs={selectedDJsData}
                  />
                </div>
              </div>
            </>
          )}

          <button type="submit" className="btn btn-primary w-full">
            {id ? 'Update Event' : 'Create Event'}
          </button>
        </form>
      </div>
    </div>
  );
}