import { Music2 } from 'lucide-react';

// Curated list of high-quality Unsplash event/rave photos
const stockImages = [
  'https://images.unsplash.com/photo-1492684223066-81342ee5ff30?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1574434312346-9e410d0b2c5e?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1429962714451-bb934ecdc4ec?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1506157786151-b8491531f063?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1598387993441-a364f854c3e1?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1459749411175-04bf5292ceea?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1496307653780-42ee777d4833?auto=format&fit=crop&w=1600',
  'https://images.unsplash.com/photo-1642178225043-f299072af862?auto=format&fit=crop&w=1600'
];

export const getRandomEventImage = () => {
  const randomIndex = Math.floor(Math.random() * stockImages.length);
  return stockImages[randomIndex];
};