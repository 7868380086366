import { format, parseISO, addMinutes } from 'date-fns';
import { DJ } from '../types';
import { useState, useEffect } from 'react';

interface TimelineViewProps {
  eventDate: string;
  duration: number;
  timeSlots: Array<{
    djId: string;
    startTime: string;
    endTime: string;
  }>;
  djs: DJ[];
}

export default function TimelineView({ eventDate, duration, timeSlots, djs }: TimelineViewProps) {
  const [isCompactView, setIsCompactView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsCompactView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const eventStart = parseISO(eventDate);
  const SLOT_HEIGHT = 64; // Height of each 30-minute slot in pixels

  // Generate timeline slots (30-minute intervals)
  const timeSlotCount = duration * 2; // 2 slots per hour
  const timelineSlots = Array.from({ length: timeSlotCount + 1 }, (_, i) => {
    const time = addMinutes(eventStart, i * 30);
    return format(time, 'h:mm a');
  });

  // Helper function to parse time string into Date object
  const parseTimeString = (timeStr: string) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date(eventStart);
    date.setHours(hours, minutes);
    // If the time is before the event start time, it must be the next day
    if (date < eventStart) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  };

  // Sort and process time slots
  const processedSlots = timeSlots
    .map(slot => {
      const startTime = parseTimeString(slot.startTime);
      const endTime = parseTimeString(slot.endTime);
      const dj = djs.find(d => d.id === slot.djId);
      if (!dj) return null;

      return {
        startTime,
        endTime,
        dj,
        displayStartTime: format(startTime, 'h:mm a'),
        displayEndTime: format(endTime, 'h:mm a'),
        startMinutes: (startTime.getTime() - eventStart.getTime()) / (1000 * 60),
        duration: (endTime.getTime() - startTime.getTime()) / (1000 * 60)
      };
    })
    .filter((slot): slot is NonNullable<typeof slot> => slot !== null)
    .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

  // Group slots by time for the mobile view
  const groupedSlots = processedSlots.reduce((acc, slot) => {
    const timeKey = `${slot.displayStartTime}-${slot.displayEndTime}`;
    if (!acc[timeKey]) {
      acc[timeKey] = {
        startTime: slot.startTime,
        displayStartTime: slot.displayStartTime,
        displayEndTime: slot.displayEndTime,
        djs: []
      };
    }
    acc[timeKey].djs.push(slot.dj);
    return acc;
  }, {} as Record<string, { startTime: Date; displayStartTime: string; displayEndTime: string; djs: DJ[] }>);

  // Sort grouped slots by start time
  const sortedGroupedSlots = Object.entries(groupedSlots)
    .map(([timeKey, data]) => ({
      timeKey,
      ...data
    }))
    .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

  // Mobile list view
  if (isCompactView) {
    return (
      <div className="space-y-6">
        {sortedGroupedSlots.map((slot, index) => (
          <div key={index} className="bg-purple-500/10 rounded-lg overflow-hidden">
            {/* Time header */}
            <div className="bg-purple-500/20 px-4 py-2 border-b border-purple-500/20">
              <div className="text-sm font-medium text-purple-300">
                {slot.displayStartTime} - {slot.displayEndTime}
              </div>
            </div>

            {/* DJs grid */}
            <div className="p-4">
              <div className={`grid gap-4 ${slot.djs.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`}>
                {slot.djs.map((dj) => (
                  <div 
                    key={dj.id}
                    className="bg-purple-500/20 backdrop-blur-sm rounded-lg border border-purple-500/40 p-3"
                  >
                    <div className="flex flex-col items-center text-center">
                      <img 
                        src={dj.profileImage} 
                        alt={dj.name}
                        className="w-16 h-16 rounded-full object-cover mb-2"
                      />
                      <h4 className="font-semibold text-white text-sm mb-1">
                        {dj.name}
                      </h4>
                      <p className="text-xs text-gray-400 line-clamp-1">
                        {dj.style.join(' · ')}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Desktop timeline view
  return (
    <div className="relative flex gap-4" style={{ height: `${(timeSlotCount + 1) * SLOT_HEIGHT}px` }}>
      {/* Time markers */}
      <div className="relative w-20 flex-shrink-0">
        {timelineSlots.map((time, i) => (
          <div 
            key={i} 
            className="absolute left-0 text-gray-400 text-sm"
            style={{ top: `${i * SLOT_HEIGHT}px` }}
          >
            {time}
          </div>
        ))}
      </div>

      {/* Timeline */}
      <div className="flex-1 relative">
        {/* Vertical lines */}
        <div className="absolute inset-0 border-l border-gray-800">
          {timelineSlots.map((_, i) => (
            <div 
              key={i}
              className="absolute w-full border-b border-gray-800"
              style={{ top: `${i * SLOT_HEIGHT}px` }}
            />
          ))}
        </div>

        {/* Group slots by time to handle overlaps */}
        {Object.entries(groupedSlots).map(([timeKey, { djs: slotDjs, displayStartTime, displayEndTime }]) => {
          const slot = processedSlots.find(s => 
            s.displayStartTime === displayStartTime && s.displayEndTime === displayEndTime
          );
          if (!slot) return null;

          const startSlot = slot.startMinutes / 30;
          const slotDuration = slot.duration / 30;
          const slotWidth = `${100 / slotDjs.length}%`;

          return slotDjs.map((dj, index) => (
            <div
              key={`${timeKey}-${dj.id}`}
              className="absolute"
              style={{
                top: `${startSlot * SLOT_HEIGHT}px`,
                height: `${slotDuration * SLOT_HEIGHT}px`,
                left: `${(index * 100) / slotDjs.length}%`,
                width: slotWidth,
                padding: '0 4px'
              }}
            >
              <div className="h-full bg-purple-500/20 backdrop-blur-sm rounded-lg border border-purple-500/40 p-3 transition-transform hover:scale-[1.02]">
                <div className="flex items-start gap-3">
                  <img 
                    src={dj.profileImage} 
                    alt={dj.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div>
                    <h4 className="font-semibold text-white">{dj.name}</h4>
                    <p className="text-sm text-purple-300">
                      {displayStartTime} - {displayEndTime}
                    </p>
                    <p className="text-xs text-gray-400 mt-1">
                      {dj.style.join(' · ')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ));
        })}
      </div>
    </div>
  );
}