import { supabase } from '../lib/supabase';

export const auth = {
  async login(password: string): Promise<boolean> {
    try {
      const { data, error } = await supabase.rpc('verify_admin_password', {
        password_attempt: password
      });

      if (error) throw error;
      
      if (data) {
        localStorage.setItem('auth_token', 'admin');
        // Update Supabase client headers
        supabase.rest.headers['app-admin-token'] = 'admin';
        return true;
      }
      
      return false;
    } catch (err) {
      console.error('Auth error:', err);
      return false;
    }
  },

  logout() {
    localStorage.removeItem('auth_token');
    // Remove admin token from Supabase client headers
    delete supabase.rest.headers['app-admin-token'];
  },

  isAuthenticated(): boolean {
    return localStorage.getItem('auth_token') === 'admin';
  }
};