import { useEffect, useState, useCallback, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { Camera, X } from 'lucide-react';

interface QRScannerProps {
  onScan: (result: string) => void;
  onError: (error: string) => void;
  paused?: boolean;
  isActive?: boolean;
  onStart?: () => void;
}

export default function QRScanner({ 
  onScan, 
  onError, 
  paused = false,
  isActive = false,
  onStart
}: QRScannerProps) {
  const [isScanning, setIsScanning] = useState(false);
  const [status, setStatus] = useState<string>('');
  const scannerRef = useRef<Html5Qrcode | null>(null);
  const mountedRef = useRef(true);
  const lastScanRef = useRef<string>('');
  const lastScanTimeRef = useRef<number>(0);
  const pausedRef = useRef(paused);
  const processingRef = useRef(false);

  // Keep pausedRef in sync with paused prop
  useEffect(() => {
    pausedRef.current = paused;
    if (!paused) {
      lastScanRef.current = '';
      lastScanTimeRef.current = 0;
      processingRef.current = false;
    }
  }, [paused]);

  const cleanupScanner = useCallback(async () => {
    if (scannerRef.current) {
      try {
        if (scannerRef.current.isScanning) {
          await scannerRef.current.stop();
        }
        scannerRef.current = null;
      } catch (error) {
        console.error('Cleanup error:', error);
      }
    }
    if (mountedRef.current) {
      setIsScanning(false);
      setStatus('');
    }
  }, []);

  const handleScanResult = useCallback((decodedText: string) => {
    if (!mountedRef.current) return;
    if (processingRef.current) return;
    if (pausedRef.current) {
      setStatus('Scanner paused - tap to continue');
      return;
    }

    // Debounce scans of the same code
    const now = Date.now();
    if (decodedText === lastScanRef.current && 
        now - lastScanTimeRef.current < 5000) {
      return;
    }

    // Set processing flag to prevent multiple scans
    processingRef.current = true;

    // Update scan tracking
    lastScanRef.current = decodedText;
    lastScanTimeRef.current = now;

    setStatus('QR Code found!');
    onScan(decodedText);
  }, [onScan]);

  const startScanning = useCallback(async () => {
    try {
      await cleanupScanner();
      setStatus('Initializing scanner...');
      
      const html5QrCode = new Html5Qrcode('qr-reader');
      scannerRef.current = html5QrCode;

      const cameras = await Html5Qrcode.getCameras();
      
      if (cameras.length === 0) {
        throw new Error('No cameras found');
      }

      const camera = cameras.find(c => 
        c.label.toLowerCase().includes('back') || 
        c.label.toLowerCase().includes('environment')
      ) || cameras[0];

      setStatus('Starting camera...');

      await html5QrCode.start(
        camera.id,
        {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          aspectRatio: 1.0
        },
        handleScanResult,
        (errorMessage) => {
          if (!errorMessage.includes('No MultiFormat Readers') && 
              !errorMessage.includes('No QR code found')) {
            if (mountedRef.current) {
              setStatus(`Scanning: ${errorMessage}`);
            }
          }
        }
      );

      if (mountedRef.current) {
        setIsScanning(true);
        setStatus(pausedRef.current ? 'Scanner paused - tap to continue' : 'Scanner ready - point at a QR code');
        if (onStart) onStart();
      }
    } catch (error) {
      const errorMessage = (error as Error).message;
      if (mountedRef.current) {
        onError(errorMessage);
        setStatus(`Error: ${errorMessage}`);
      }
      await cleanupScanner();
    }
  }, [onError, cleanupScanner, onStart, handleScanResult]);

  // Update status when paused state changes
  useEffect(() => {
    if (isScanning) {
      setStatus(paused ? 'Scanner paused - tap to continue' : 'Scanner ready - point at a QR code');
    }
  }, [paused, isScanning]);

  // Start/stop scanner based on isActive
  useEffect(() => {
    if (isActive && !isScanning) {
      startScanning();
    } else if (!isActive && isScanning) {
      cleanupScanner();
    }
  }, [isActive, isScanning, startScanning, cleanupScanner]);

  // Cleanup on unmount
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      cleanupScanner().catch(console.error);
    };
  }, [cleanupScanner]);

  return (
    <div className="relative">
      <div className="text-center mb-4">
        {!isScanning ? (
          <button
            onClick={startScanning}
            className="btn btn-primary inline-flex items-center"
          >
            <Camera className="w-5 h-5 mr-2" />
            Start Scanner
          </button>
        ) : (
          <button
            onClick={cleanupScanner}
            className="btn bg-red-600 hover:bg-red-700 text-white inline-flex items-center"
          >
            <X className="w-5 h-5 mr-2" />
            Stop Scanner
          </button>
        )}
      </div>

      {status && (
        <div className="text-center text-sm text-gray-400 mb-4">
          {status}
        </div>
      )}

      <div className="bg-black/50 backdrop-blur-sm rounded-lg overflow-hidden">
        <div id="qr-reader" className="w-full max-w-md mx-auto aspect-square"></div>
      </div>
    </div>
  );
}