import { useState } from 'react';
import { X, Send, Lock } from 'lucide-react';
import { Event } from '../types';

interface RSVPModalProps {
  event: Event;
  onClose: () => void;
  onVerified: () => void;
}

export default function RSVPModal({ event, onClose, onVerified }: RSVPModalProps) {
  const [step, setStep] = useState<'initial' | 'sms' | 'captcha' | 'verified'>('initial');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [error, setError] = useState<string | null>(null);

  // Simple captcha generation
  const captchaText = '2F9X4K';

  const handleSMSSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // In a real app, this would send an SMS via your backend
    if (phoneNumber.length < 10) {
      setError('Please enter a valid phone number');
      return;
    }
    
    if (event.rsvpOptions.requireCaptcha) {
      setStep('captcha');
    } else {
      setStep('verified');
      onVerified();
    }
  };

  const handleCaptchaSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (captchaValue.toUpperCase() !== captchaText) {
      setError('Incorrect captcha value');
      return;
    }
    
    if (event.rsvpOptions.requireSMS && step !== 'sms') {
      setStep('sms');
    } else {
      setStep('verified');
      onVerified();
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg w-full max-w-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="text-center mb-6">
          <Lock className="w-12 h-12 text-purple-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-white mb-2">Secret Location</h2>
          <p className="text-gray-400">
            Verify your identity to reveal the event location
          </p>
        </div>

        {step === 'initial' && (
          <div className="space-y-4">
            {event.rsvpOptions.requireSMS && (
              <button
                onClick={() => setStep('sms')}
                className="w-full btn btn-primary"
              >
                Verify via SMS
              </button>
            )}
            {event.rsvpOptions.requireCaptcha && (
              <button
                onClick={() => setStep('captcha')}
                className="w-full btn btn-primary"
              >
                Verify with Captcha
              </button>
            )}
          </div>
        )}

        {step === 'sms' && (
          <form onSubmit={handleSMSSubmit} className="space-y-4">
            <div>
              <label className="block text-white mb-2">Phone Number</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
                className="input bg-white/10 text-white border-gray-700"
              />
            </div>
            {error && (
              <p className="text-red-400 text-sm">{error}</p>
            )}
            <button type="submit" className="w-full btn btn-primary">
              <Send className="w-4 h-4 mr-2" />
              Send Verification Code
            </button>
          </form>
        )}

        {step === 'captcha' && (
          <form onSubmit={handleCaptchaSubmit} className="space-y-4">
            <div>
              <label className="block text-white mb-2">Enter Captcha</label>
              <div className="bg-white/10 p-4 rounded-lg mb-4 text-center">
                <span className="font-mono text-2xl text-purple-400 tracking-widest">
                  {captchaText}
                </span>
              </div>
              <input
                type="text"
                value={captchaValue}
                onChange={(e) => setCaptchaValue(e.target.value)}
                placeholder="Enter the code above"
                className="input bg-white/10 text-white border-gray-700"
              />
            </div>
            {error && (
              <p className="text-red-400 text-sm">{error}</p>
            )}
            <button type="submit" className="w-full btn btn-primary">
              Verify Captcha
            </button>
          </form>
        )}

        {step === 'verified' && (
          <div className="text-center">
            <p className="text-green-400 mb-4">✓ Verification Complete</p>
            <p className="text-white font-medium mb-2">Event Location:</p>
            <p className="text-purple-400">{event.location}</p>
          </div>
        )}
      </div>
    </div>
  );
}