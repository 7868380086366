import { useEffect, useState } from 'react';
import { Event } from '../types';
import EventCard from '../components/EventCard';
import { api } from '../services/api';

export default function Events() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    api.getEvents()
      .then(data => {
        setEvents(data);
        setError(null);
      })
      .catch(err => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white mb-12">All Events</h1>
        
        {loading ? (
          <div className="space-y-8">
            {[1, 2, 3].map(i => (
              <div key={i} className="bg-white/10 rounded-lg h-48 animate-pulse" />
            ))}
          </div>
        ) : error ? (
          <div className="text-red-400 p-4 bg-red-400/10 rounded-lg">{error}</div>
        ) : (
          <div className="grid grid-cols-1 gap-8">
            {events.map(event => (
              <EventCard key={event.id} event={event} expanded />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}