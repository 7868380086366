import { X } from 'lucide-react';
import { Event } from '../../types';
import TicketPurchaseForm from './TicketPurchaseForm';

interface TicketPurchaseModalProps {
  event: Event;
  onClose: () => void;
}

export default function TicketPurchaseModal({ event, onClose }: TicketPurchaseModalProps) {
  const handleSuccess = () => {
    // Show success message and close modal
    setTimeout(onClose, 2000);
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg w-full max-w-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition"
        >
          <X className="w-5 h-5" />
        </button>

        <TicketPurchaseForm 
          event={event} 
          onSuccess={handleSuccess} 
        />
      </div>
    </div>
  );
}