import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDJStore } from '../../store/djStore';
import { DJ } from '../../types';
import ImageUpload from '../../components/ImageUpload';

export default function DJEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { djs, loading, fetchDJs, addDJ, updateDJ } = useDJStore();
  const dj = djs.find(d => d.id === id);

  useEffect(() => {
    fetchDJs().catch(err => setError(err.message));
  }, [fetchDJs]);

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<DJ>({
    defaultValues: {
      name: '',
      profileImage: '',
      style: [],
      socialMedia: {
        instagram: '',
        soundcloud: '',
        twitter: ''
      },
      bio: ''
    }
  });

  // Reset form when DJ data is loaded
  useEffect(() => {
    if (dj) {
      reset({
        ...dj,
        style: dj.style.join(', ')
      });
    }
  }, [dj, reset]);

  const onSubmit = async (data: Omit<DJ, 'id'>) => {
    try {
      setError(null);
      const formattedData = {
        ...data,
        style: typeof data.style === 'string' 
          ? data.style.split(',').map(s => s.trim()).filter(Boolean)
          : data.style,
        socialMedia: {
          instagram: data.socialMedia?.instagram || '',
          soundcloud: data.socialMedia?.soundcloud || '',
          twitter: data.socialMedia?.twitter || ''
        }
      };

      if (id) {
        await updateDJ(id, formattedData);
      } else {
        await addDJ(formattedData);
      }
      navigate('/admin/djs');
    } catch (err) {
      setError((err as Error).message);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-white">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white mb-8">
          {id ? 'Edit DJ Profile' : 'Create New DJ Profile'}
        </h1>

        {error && (
          <div className="mb-6 p-4 bg-red-500/20 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-white mb-2">DJ Name</label>
            <input
              {...register('name', { required: 'Name is required' })}
              className="input bg-white/10 text-white border-gray-700"
            />
            {errors.name && (
              <span className="text-red-400 text-sm mt-1">{errors.name.message}</span>
            )}
          </div>

          <div>
            <label className="block text-white mb-2">Profile Image</label>
            <ImageUpload
              onUpload={(url) => setValue('profileImage', url)}
              defaultImage={dj?.profileImage}
              folder="dj-profiles"
            />
            {errors.profileImage && (
              <span className="text-red-400 text-sm mt-1">{errors.profileImage.message}</span>
            )}
          </div>

          <div>
            <label className="block text-white mb-2">Music Styles</label>
            <input
              {...register('style')}
              placeholder="Techno, House, Trance..."
              className="input bg-white/10 text-white border-gray-700"
            />
            <p className="text-gray-400 text-sm mt-1">Separate styles with commas</p>
          </div>

          <div>
            <label className="block text-white mb-2">Social Media</label>
            <div className="space-y-4">
              <input
                {...register('socialMedia.instagram')}
                placeholder="Instagram handle (e.g., @djname)"
                className="input bg-white/10 text-white border-gray-700"
              />
              <input
                {...register('socialMedia.soundcloud')}
                placeholder="Soundcloud username"
                className="input bg-white/10 text-white border-gray-700"
              />
              <input
                {...register('socialMedia.twitter')}
                placeholder="Twitter handle (e.g., @djname)"
                className="input bg-white/10 text-white border-gray-700"
              />
            </div>
          </div>

          <div>
            <label className="block text-white mb-2">Bio</label>
            <textarea
              {...register('bio', { required: 'Bio is required' })}
              className="input bg-white/10 text-white border-gray-700"
              rows={4}
            />
            {errors.bio && (
              <span className="text-red-400 text-sm mt-1">{errors.bio.message}</span>
            )}
          </div>

          <button type="submit" className="btn btn-primary w-full">
            {id ? 'Update Profile' : 'Create Profile'}
          </button>
        </form>
      </div>
    </div>
  );
}