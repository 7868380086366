import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Mail, RefreshCw, QrCode, Search } from 'lucide-react';
import { useTicketStore } from '../../store/ticketStore';
import { Event, Ticket } from '../../types';
import { supabase } from '../../lib/supabase';
import QRCodeModal from '../../components/tickets/QRCodeModal';

export default function TicketList() {
  const { tickets, loading, error, fetchTickets, refundTicket, resendEmail } = useTicketStore();
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

  useEffect(() => {
    // Fetch events for the filter
    const fetchEvents = async () => {
      const { data } = await supabase
        .from('events')
        .select('*')
        .order('date', { ascending: false });
      
      if (data) {
        setEvents(data);
      }
    };

    fetchEvents();
    fetchTickets();
  }, [fetchTickets]);

  // Filter tickets based on search term and selected event
  const filteredTickets = tickets.filter(ticket => {
    const matchesSearch = searchTerm === '' || 
      ticket.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ticket.customerEmail.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesEvent = selectedEvent === '' || ticket.eventId === selectedEvent;
    
    return matchesSearch && matchesEvent;
  });

  const handleRefund = async (ticketId: string) => {
    if (window.confirm('Are you sure you want to refund this ticket?')) {
      try {
        await refundTicket(ticketId);
      } catch (error) {
        console.error('Error refunding ticket:', error);
      }
    }
  };

  const handleResendEmail = async (ticketId: string) => {
    try {
      await resendEmail(ticketId);
    } catch (error) {
      console.error('Error resending email:', error);
    }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case 'valid':
        return 'bg-green-500/20 text-green-300';
      case 'used':
        return 'bg-blue-500/20 text-blue-300';
      case 'refunded':
        return 'bg-red-500/20 text-red-300';
      default:
        return 'bg-gray-500/20 text-gray-300';
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white mb-8">Ticket Management</h1>

        {/* Filters */}
        <div className="mb-8 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-white mb-2">Filter by Event</label>
            <select
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              className="input bg-white/10 text-white border-gray-700"
            >
              <option value="">All Events</option>
              {events.map(event => (
                <option key={event.id} value={event.id}>
                  {event.name} - {format(new Date(event.date), 'PP')}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-white mb-2">Search Tickets</label>
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by name or email..."
                className="input bg-white/10 text-white border-gray-700 pl-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-500/20 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        {/* Tickets Table */}
        <div className="bg-white/10 rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-700">
                  <th className="px-6 py-3 text-left text-white">Customer</th>
                  <th className="px-6 py-3 text-left text-white">Event</th>
                  <th className="px-6 py-3 text-left text-white">Purchase Date</th>
                  <th className="px-6 py-3 text-left text-white">Status</th>
                  <th className="px-6 py-3 text-right text-white">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5} className="px-6 py-4 text-center text-gray-400">
                      Loading tickets...
                    </td>
                  </tr>
                ) : filteredTickets.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="px-6 py-4 text-center text-gray-400">
                      No tickets found
                    </td>
                  </tr>
                ) : (
                  filteredTickets.map(ticket => {
                    const event = events.find(e => e.id === ticket.eventId);
                    return (
                      <tr key={ticket.id} className="border-b border-gray-700">
                        <td className="px-6 py-4">
                          <div className="text-white font-medium">{ticket.customerName}</div>
                          <div className="text-gray-400 text-sm">{ticket.customerEmail}</div>
                        </td>
                        <td className="px-6 py-4 text-white">
                          {event?.name || 'Unknown Event'}
                        </td>
                        <td className="px-6 py-4 text-white">
                          {format(new Date(ticket.purchaseDate), 'PP')}
                        </td>
                        <td className="px-6 py-4">
                          <span className={`px-2 py-1 rounded-full text-xs ${getStatusBadgeClass(ticket.status)}`}>
                            {ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-end space-x-2">
                            <button
                              onClick={() => setSelectedTicket(ticket)}
                              className="p-2 text-gray-400 hover:text-purple-400 transition"
                              title="View QR code"
                            >
                              <QrCode className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleResendEmail(ticket.id)}
                              className="p-2 text-gray-400 hover:text-blue-400 transition"
                              title="Resend ticket email"
                            >
                              <Mail className="h-5 w-5" />
                            </button>
                            {ticket.status === 'valid' && (
                              <button
                                onClick={() => handleRefund(ticket.id)}
                                className="p-2 text-gray-400 hover:text-red-400 transition"
                                title="Refund ticket"
                              >
                                <RefreshCw className="h-5 w-5" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* QR Code Modal */}
      {selectedTicket && (
        <QRCodeModal
          ticket={selectedTicket}
          onClose={() => setSelectedTicket(null)}
        />
      )}
    </div>
  );
}