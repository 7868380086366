import { useState, useEffect } from 'react';
import { Ticket, Event } from '../../types';
import { tickets } from '../../services/tickets';
import { CheckCircle, XCircle, AlertCircle, Calendar, Clock } from 'lucide-react';
import QRScanner from '../../components/scanner/QRScanner';
import { supabase } from '../../lib/supabase';
import { format, parseISO } from 'date-fns';

export default function TicketScanner() {
  const [scanError, setScanError] = useState<string>('');
  const [lastScan, setLastScan] = useState<{
    valid: boolean;
    ticket?: Ticket;
    event?: Event;
    timestamp: number;
  } | null>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const handleScan = async (decodedText: string) => {
    try {
      // Set pause state BEFORE processing the scan
      setIsPaused(true);
      setScanError('');

      let ticketData;
      try {
        ticketData = JSON.parse(decodedText);
      } catch (e) {
        throw new Error('Invalid QR code format');
      }

      if (!ticketData.ticketId) {
        throw new Error('Invalid ticket data');
      }

      const result = await tickets.verifyTicket(ticketData.ticketId);

      // Fetch event details if we have a ticket
      let event: Event | undefined;
      if (result.ticket) {
        const { data: eventData } = await supabase
          .from('events')
          .select('*')
          .eq('id', result.ticket.eventId)
          .single();

        if (eventData) {
          event = {
            id: eventData.id,
            name: eventData.name,
            date: eventData.date,
            duration: eventData.duration,
            location: eventData.location,
            image: eventData.image,
            ticketed: eventData.ticketed,
            price: eventData.price,
            description: eventData.description,
            isSecretLocation: eventData.is_secret_location,
            rsvpOptions: eventData.rsvp_options,
            djs: [],
            timeSlots: []
          };
        }
      }

      setLastScan({
        valid: result.valid,
        ticket: result.ticket,
        event,
        timestamp: Date.now()
      });

    } catch (err) {
      setScanError((err as Error).message);
      setLastScan({
        valid: false,
        timestamp: Date.now()
      });
    }
  };

  const handleError = (error: string) => {
    setScanError(error);
  };

  const handleContinue = () => {
    setLastScan(null);
    setScanError('');
    setIsPaused(false);
  };

  const handleStartScanning = () => {
    setIsScanning(true);
    setIsPaused(false);
    setScanError('');
    setLastScan(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-white mb-8 text-center">
          Ticket Scanner
        </h1>

        {/* Scanner with Overlaid Result */}
        <div className="relative mb-8">
          <QRScanner 
            onScan={handleScan}
            onError={handleError}
            paused={isPaused}
            isActive={isScanning}
            onStart={handleStartScanning}
          />

          {/* Overlaid Result */}
          {lastScan && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/90 backdrop-blur-sm rounded-lg">
              <div className={`w-full max-w-sm mx-4 p-6 rounded-lg ${
                lastScan.valid 
                  ? 'bg-green-500/20 border border-green-500' 
                  : 'bg-red-500/20 border border-red-500'
              }`}>
                <div className="flex items-center justify-center mb-4">
                  {lastScan.valid ? (
                    <CheckCircle className="w-16 h-16 text-green-400" />
                  ) : (
                    <XCircle className="w-16 h-16 text-red-400" />
                  )}
                </div>

                <div className="text-center mb-6">
                  <h3 className={`text-xl font-bold mb-2 ${
                    lastScan.valid ? 'text-green-400' : 'text-red-400'
                  }`}>
                    {lastScan.valid ? 'Valid Ticket' : 'Invalid Ticket'}
                  </h3>

                  {lastScan.ticket && (
                    <div className="space-y-4">
                      {/* Event Details */}
                      {lastScan.event && (
                        <div className="p-3 bg-white/10 rounded-lg">
                          <div className="flex items-center justify-center gap-2 text-purple-400 mb-1">
                            <Calendar className="w-4 h-4" />
                            <span className="font-medium">{lastScan.event.name}</span>
                          </div>
                          <p className="text-sm text-gray-400">
                            {format(parseISO(lastScan.event.date), 'PPP')}
                          </p>
                        </div>
                      )}

                      {/* Customer Details */}
                      <div className="text-white">
                        <p className="mb-1">{lastScan.ticket.customerName}</p>
                        <p className="text-sm text-gray-400">
                          {lastScan.ticket.customerEmail}
                        </p>
                      </div>

                      {/* Usage Time */}
                      {lastScan.ticket.status === 'used' && lastScan.ticket.usedDate && (
                        <div className="flex items-center justify-center gap-2 text-blue-400 text-sm">
                          <Clock className="w-4 h-4" />
                          <span>
                            Used on {format(parseISO(lastScan.ticket.usedDate), 'PPp')}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <button
                  onClick={handleContinue}
                  className="w-full btn btn-primary"
                >
                  Tap to Continue Scanning
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Error Message */}
        {scanError && (
          <div className="p-4 bg-red-500/20 border border-red-500 rounded-lg">
            <div className="flex items-center justify-center">
              <AlertCircle className="w-5 h-5 text-red-400 mr-2" />
              <p className="text-red-400">{scanError}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}