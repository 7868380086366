import { create } from 'zustand';
import { Ticket, TicketPurchase } from '../types';
import { tickets } from '../services/tickets';

interface TicketStore {
  tickets: Ticket[];
  loading: boolean;
  error: string | null;
  fetchTickets: (eventId?: string) => Promise<void>;
  purchaseTicket: (purchase: TicketPurchase) => Promise<Ticket>;
  refundTicket: (ticketId: string) => Promise<void>;
  resendEmail: (ticketId: string) => Promise<void>;
}

export const useTicketStore = create<TicketStore>((set, get) => ({
  tickets: [],
  loading: false,
  error: null,
  fetchTickets: async (eventId?: string) => {
    set({ loading: true, error: null });
    try {
      const fetchedTickets = await tickets.getTickets(eventId);
      set({ tickets: fetchedTickets, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
  purchaseTicket: async (purchase: TicketPurchase) => {
    set({ loading: true, error: null });
    try {
      const ticket = await tickets.purchaseTicket(purchase);
      set(state => ({
        tickets: [ticket, ...state.tickets],
        loading: false
      }));
      return ticket;
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
  refundTicket: async (ticketId: string) => {
    set({ loading: true, error: null });
    try {
      await tickets.refundTicket(ticketId);
      set(state => ({
        tickets: state.tickets.map(t => 
          t.id === ticketId ? { ...t, status: 'refunded', refundDate: new Date().toISOString() } : t
        ),
        loading: false
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
  resendEmail: async (ticketId: string) => {
    set({ loading: true, error: null });
    try {
      await tickets.resendTicketEmail(ticketId);
      set({ loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  }
}));