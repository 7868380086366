import { Calendar, MapPin, Ticket, Lock } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Event, DJ } from '../types';
import { supabase } from '../lib/supabase';

interface EventCardProps {
  event: Event;
  expanded?: boolean;
}

export default function EventCard({ event, expanded = false }: EventCardProps) {
  const [djs, setDjs] = useState<DJ[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDJs = async () => {
      try {
        // Fetch DJs directly from Supabase using IN clause
        const { data, error } = await supabase
          .from('djs')
          .select('*')
          .in('id', event.djs);

        if (error) throw error;

        // Transform the data to match our DJ type
        const djData: DJ[] = data.map(dj => ({
          id: dj.id,
          name: dj.name,
          profileImage: dj.profile_image,
          style: dj.style,
          socialMedia: dj.social_media,
          bio: dj.bio
        }));

        setDjs(djData);
      } catch (error) {
        console.error('Error fetching DJs:', error);
      } finally {
        setLoading(false);
      }
    };

    if (event.djs.length > 0) {
      fetchDJs();
    } else {
      setLoading(false);
    }
  }, [event.djs]);

  return (
    <div className={`bg-white/10 backdrop-blur-sm rounded-lg shadow-xl overflow-hidden ${expanded ? 'flex' : ''}`}>
      <Link 
        to={`/events/${event.id}`}
        className={`block ${expanded ? 'w-1/3' : 'w-full'}`}
      >
        <img 
          src={event.image} 
          alt={event.name}
          className={`object-cover w-full ${expanded ? 'h-full' : 'h-48'}`}
        />
      </Link>
      <div className="p-6 flex-1">
        <Link to={`/events/${event.id}`}>
          <h3 className="text-2xl font-bold text-white hover:text-purple-400 transition">
            {event.name}
          </h3>
        </Link>
        
        <div className="mt-4 space-y-2">
          <div className="flex items-center text-gray-300">
            <Calendar className="h-5 w-5 mr-2" />
            {format(parseISO(event.date), 'PPP')}
          </div>
          
          <div className="flex items-center text-gray-300">
            <MapPin className="h-5 w-5 mr-2" />
            {event.isSecretLocation ? (
              <span className="flex items-center text-purple-400">
                <Lock className="h-4 w-4 mr-1" />
                Secret Location
              </span>
            ) : (
              event.location
            )}
          </div>
          
          <div className="flex items-center text-gray-300">
            <Ticket className="h-5 w-5 mr-2" />
            {event.ticketed ? `$${event.price}` : 'Free Entry'}
          </div>
        </div>

        {expanded && !loading && djs.length > 0 && (
          <div className="mt-6">
            <h4 className="font-semibold text-white mb-3">Featured DJs</h4>
            <div className="flex flex-wrap gap-4">
              {djs.map(dj => (
                <div key={dj.id} className="flex items-center space-x-2">
                  <img 
                    src={dj.profileImage} 
                    alt={dj.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div>
                    <p className="font-medium text-white">{dj.name}</p>
                    <p className="text-sm text-gray-400">{dj.style.join(', ')}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}