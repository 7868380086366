import { supabase } from '../lib/supabase';
import { Event, DJ } from '../types';

const validateInput = (input: any, schema: any) => {
  if (!input || typeof input !== 'object') {
    throw new Error('Invalid input');
  }
  return true;
};

export const api = {
  getEvents: async (): Promise<Event[]> => {
    const { data, error } = await supabase
      .from('events')
      .select(`
        *,
        event_djs!inner (
          dj_id,
          start_time,
          end_time
        )
      `)
      .order('date', { ascending: true });

    if (error) throw error;
    if (!data) return [];

    return data.map(event => ({
      id: event.id,
      name: event.name,
      date: event.date,
      duration: event.duration,
      location: event.location,
      image: event.image,
      ticketed: event.ticketed,
      price: event.price || undefined,
      description: event.description,
      isSecretLocation: event.is_secret_location,
      rsvpOptions: event.rsvp_options,
      djs: event.event_djs?.map(ed => ed.dj_id) || [],
      timeSlots: event.event_djs?.map(ed => ({
        djId: ed.dj_id,
        startTime: ed.start_time,
        endTime: ed.end_time
      })) || []
    }));
  },

  getDJs: async (): Promise<DJ[]> => {
    const { data, error } = await supabase
      .from('djs')
      .select('*')
      .order('name');

    if (error) throw error;
    if (!data) return [];

    return data.map(dj => ({
      id: dj.id,
      name: dj.name,
      profileImage: dj.profile_image,
      style: dj.style || [],
      socialMedia: dj.social_media || {},
      bio: dj.bio
    }));
  },

  createDJ: async (dj: Omit<DJ, 'id'>): Promise<DJ> => {
    validateInput(dj, 'DJSchema');

    const { data, error } = await supabase
      .from('djs')
      .insert({
        name: dj.name,
        profile_image: dj.profileImage,
        style: dj.style || [],
        social_media: dj.socialMedia || {},
        bio: dj.bio
      })
      .select('*')
      .single();

    if (error) throw error;
    if (!data) {
      throw new Error('Failed to create DJ');
    }

    return {
      id: data.id,
      name: data.name,
      profileImage: data.profile_image,
      style: data.style || [],
      socialMedia: data.social_media || {},
      bio: data.bio
    };
  },

  updateDJ: async (id: string, dj: Partial<DJ>): Promise<DJ> => {
    validateInput(dj, 'DJSchema');

    const updates: any = {};
    if (dj.name !== undefined) updates.name = dj.name;
    if (dj.profileImage !== undefined) updates.profile_image = dj.profileImage;
    if (dj.style !== undefined) updates.style = dj.style;
    if (dj.socialMedia !== undefined) updates.social_media = dj.socialMedia;
    if (dj.bio !== undefined) updates.bio = dj.bio;

    const { data, error } = await supabase
      .from('djs')
      .update(updates)
      .eq('id', id)
      .select('*')
      .single();

    if (error) throw error;
    if (!data) {
      throw new Error('DJ not found');
    }

    return {
      id: data.id,
      name: data.name,
      profileImage: data.profile_image,
      style: data.style || [],
      socialMedia: data.social_media || {},
      bio: data.bio
    };
  },

  deleteDJ: async (id: string): Promise<void> => {
    const { error } = await supabase
      .from('djs')
      .delete()
      .eq('id', id);

    if (error) throw error;
  },

  createEvent: async (event: Omit<Event, 'id'>): Promise<Event> => {
    validateInput(event, 'EventSchema');

    const { data: eventData, error: eventError } = await supabase
      .from('events')
      .insert({
        name: event.name,
        date: event.date,
        duration: event.duration,
        location: event.location,
        image: event.image,
        ticketed: event.ticketed,
        price: event.price,
        description: event.description,
        is_secret_location: event.isSecretLocation,
        rsvp_options: event.rsvpOptions
      })
      .select('*')
      .single();

    if (eventError) throw eventError;
    if (!eventData) {
      throw new Error('Failed to create event');
    }

    if (event.timeSlots.length > 0) {
      const { error: slotsError } = await supabase
        .from('event_djs')
        .insert(
          event.timeSlots.map(slot => ({
            event_id: eventData.id,
            dj_id: slot.djId,
            start_time: slot.startTime,
            end_time: slot.endTime
          }))
        );

      if (slotsError) throw slotsError;
    }

    return {
      ...event,
      id: eventData.id,
      djs: event.timeSlots.map(slot => slot.djId)
    };
  },

  updateEvent: async (id: string, event: Partial<Event>): Promise<Event> => {
    validateInput(event, 'EventSchema');

    const updates: any = {};
    if (event.name !== undefined) updates.name = event.name;
    if (event.date !== undefined) updates.date = event.date;
    if (event.duration !== undefined) updates.duration = event.duration;
    if (event.location !== undefined) updates.location = event.location;
    if (event.image !== undefined) updates.image = event.image;
    if (event.ticketed !== undefined) updates.ticketed = event.ticketed;
    if (event.price !== undefined) updates.price = event.price;
    if (event.description !== undefined) updates.description = event.description;
    if (event.isSecretLocation !== undefined) updates.is_secret_location = event.isSecretLocation;
    if (event.rsvpOptions !== undefined) updates.rsvp_options = event.rsvpOptions;

    const { data: eventData, error: eventError } = await supabase
      .from('events')
      .update(updates)
      .eq('id', id)
      .select('*')
      .single();

    if (eventError) throw eventError;
    if (!eventData) {
      throw new Error('Event not found');
    }

    if (event.timeSlots) {
      // Delete existing time slots
      const { error: deleteError } = await supabase
        .from('event_djs')
        .delete()
        .eq('event_id', id);

      if (deleteError) throw deleteError;

      // Insert new time slots if any
      if (event.timeSlots.length > 0) {
        const { error: slotsError } = await supabase
          .from('event_djs')
          .insert(
            event.timeSlots.map(slot => ({
              event_id: id,
              dj_id: slot.djId,
              start_time: slot.startTime,
              end_time: slot.endTime
            }))
          );

        if (slotsError) throw slotsError;
      }
    }

    // Fetch the updated event with all its relations
    const { data: updatedEvent, error: fetchError } = await supabase
      .from('events')
      .select(`
        *,
        event_djs!inner (
          dj_id,
          start_time,
          end_time
        )
      `)
      .eq('id', id)
      .single();

    if (fetchError) throw fetchError;
    if (!updatedEvent) {
      throw new Error('Failed to fetch updated event');
    }

    return {
      id: updatedEvent.id,
      name: updatedEvent.name,
      date: updatedEvent.date,
      duration: updatedEvent.duration,
      location: updatedEvent.location,
      image: updatedEvent.image,
      ticketed: updatedEvent.ticketed,
      price: updatedEvent.price || undefined,
      description: updatedEvent.description,
      isSecretLocation: updatedEvent.is_secret_location,
      rsvpOptions: updatedEvent.rsvp_options,
      djs: updatedEvent.event_djs?.map(ed => ed.dj_id) || [],
      timeSlots: updatedEvent.event_djs?.map(ed => ({
        djId: ed.dj_id,
        startTime: ed.start_time,
        endTime: ed.end_time
      })) || []
    };
  },

  deleteEvent: async (id: string): Promise<void> => {
    // Delete associated time slots first
    const { error: slotsError } = await supabase
      .from('event_djs')
      .delete()
      .eq('event_id', id);

    if (slotsError) throw slotsError;

    // Then delete the event
    const { error } = await supabase
      .from('events')
      .delete()
      .eq('id', id);

    if (error) throw error;
  }
};