import { supabase } from '../lib/supabase';
import { Ticket, TicketPurchase } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const tickets = {
  async getTickets(eventId?: string): Promise<Ticket[]> {
    try {
      let query = supabase.from('tickets').select('*');
      
      if (eventId) {
        query = query.eq('event_id', eventId);
      }
      
      const { data, error } = await query.order('purchase_date', { ascending: false });
      
      if (error) throw error;
      
      return data.map(ticket => ({
        id: ticket.id,
        eventId: ticket.event_id,
        customerEmail: ticket.customer_email,
        customerName: ticket.customer_name,
        stripePaymentId: ticket.stripe_payment_id || 'DEMO',
        qrCode: ticket.qr_code,
        status: ticket.status,
        purchaseDate: ticket.purchase_date,
        usedDate: ticket.used_date,
        refundDate: ticket.refund_date
      }));
    } catch (error) {
      console.error('Error fetching tickets:', error);
      return [];
    }
  },

  async purchaseTicket(purchase: TicketPurchase): Promise<Ticket> {
    try {
      const ticketId = uuidv4();
      const ticketPayload = JSON.stringify({
        ticketId,
        customerEmail: purchase.customerEmail
      });

      const ticketData = {
        id: ticketId,
        event_id: purchase.eventId,
        customer_email: purchase.customerEmail,
        customer_name: purchase.customerName,
        stripe_payment_id: 'DEMO',
        qr_code: ticketPayload,
        status: 'valid',
        purchase_date: new Date().toISOString()
      };

      const { data: ticket, error } = await supabase
        .from('tickets')
        .insert(ticketData)
        .select()
        .single();

      if (error) throw error;

      return {
        id: ticket.id,
        eventId: ticket.event_id,
        customerEmail: ticket.customer_email,
        customerName: ticket.customer_name,
        stripePaymentId: ticket.stripe_payment_id,
        qrCode: ticket.qr_code,
        status: ticket.status,
        purchaseDate: ticket.purchase_date,
        usedDate: ticket.used_date,
        refundDate: ticket.refund_date
      };
    } catch (error) {
      console.error('Error purchasing ticket:', error);
      throw error;
    }
  },

  async verifyTicket(ticketId: string): Promise<{ valid: boolean; ticket?: Ticket }> {
    try {
      const { data: ticket, error } = await supabase
        .from('tickets')
        .select('*')
        .eq('id', ticketId)
        .single();

      if (error) throw error;

      if (!ticket) {
        return { valid: false };
      }

      const isValid = ticket.status === 'valid';

      // If valid, mark as used
      if (isValid) {
        await supabase
          .from('tickets')
          .update({
            status: 'used',
            used_date: new Date().toISOString()
          })
          .eq('id', ticketId);
      }

      return {
        valid: isValid,
        ticket: {
          id: ticket.id,
          eventId: ticket.event_id,
          customerEmail: ticket.customer_email,
          customerName: ticket.customer_name,
          stripePaymentId: ticket.stripe_payment_id,
          qrCode: ticket.qr_code,
          status: isValid ? 'used' : ticket.status,
          purchaseDate: ticket.purchase_date,
          usedDate: isValid ? new Date().toISOString() : ticket.used_date,
          refundDate: ticket.refund_date
        }
      };
    } catch (error) {
      console.error('Error verifying ticket:', error);
      throw error;
    }
  },

  async refundTicket(ticketId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('tickets')
        .update({
          status: 'refunded',
          refund_date: new Date().toISOString()
        })
        .eq('id', ticketId);

      if (error) throw error;
    } catch (error) {
      console.error('Error refunding ticket:', error);
      throw error;
    }
  },

  async resendTicketEmail(ticketId: string): Promise<void> {
    try {
      // In demo mode, just simulate email resend
      console.log('Demo: Resending ticket email for ticket:', ticketId);
    } catch (error) {
      console.error('Error resending ticket email:', error);
      throw error;
    }
  }
};