import { create } from 'zustand';
import { Event } from '../types';
import { api } from '../services/api';

interface EventStore {
  events: Event[];
  loading: boolean;
  error: string | null;
  fetchEvents: () => Promise<void>;
  addEvent: (event: Omit<Event, 'id'>) => Promise<void>;
  updateEvent: (id: string, event: Partial<Event>) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
}

export const useEventStore = create<EventStore>((set) => ({
  events: [],
  loading: false,
  error: null,
  fetchEvents: async () => {
    set({ loading: true, error: null });
    try {
      const events = await api.getEvents();
      set({ events, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      console.error('Failed to fetch events:', error);
    }
  },
  addEvent: async (event) => {
    set({ loading: true, error: null });
    try {
      const newEvent = await api.createEvent(event);
      set((state) => ({ 
        events: [...state.events, newEvent],
        loading: false 
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
  updateEvent: async (id, event) => {
    set({ loading: true, error: null });
    try {
      const updatedEvent = await api.updateEvent(id, event);
      set((state) => ({
        events: state.events.map((e) => (e.id === id ? updatedEvent : e)),
        loading: false
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
  deleteEvent: async (id) => {
    set({ loading: true, error: null });
    try {
      await api.deleteEvent(id);
      set((state) => ({
        events: state.events.filter((e) => e.id !== id),
        loading: false
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
}));