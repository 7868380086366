import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Calendar, MapPin, Ticket, Clock, Music, Lock, CreditCard } from 'lucide-react';
import { Event, DJ } from '../types';
import { supabase } from '../lib/supabase';
import RSVPModal from '../components/RSVPModal';
import TimelineView from '../components/TimelineView';
import TicketPurchaseModal from '../components/tickets/TicketPurchaseModal';

export default function EventDetails() {
  const { id } = useParams();
  const [event, setEvent] = useState<Event | null>(null);
  const [djs, setDjs] = useState<DJ[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [locationRevealed, setLocationRevealed] = useState(false);

  useEffect(() => {
    const fetchEventAndDJs = async () => {
      if (!id) return;

      try {
        setLoading(true);
        // Fetch event details
        const { data: eventData, error: eventError } = await supabase
          .from('events')
          .select(`
            *,
            event_djs (
              dj_id,
              start_time,
              end_time
            )
          `)
          .eq('id', id)
          .single();

        if (eventError) throw eventError;

        // Transform event data
        const transformedEvent: Event = {
          id: eventData.id,
          name: eventData.name,
          date: eventData.date,
          duration: eventData.duration,
          location: eventData.location,
          image: eventData.image,
          ticketed: eventData.ticketed,
          price: eventData.price || undefined,
          description: eventData.description,
          isSecretLocation: eventData.is_secret_location,
          rsvpOptions: eventData.rsvp_options,
          djs: eventData.event_djs.map((ed: any) => ed.dj_id),
          timeSlots: eventData.event_djs.map((ed: any) => ({
            djId: ed.dj_id,
            startTime: ed.start_time,
            endTime: ed.end_time
          }))
        };

        setEvent(transformedEvent);

        // Fetch DJs if there are any
        if (transformedEvent.djs.length > 0) {
          const { data: djsData, error: djsError } = await supabase
            .from('djs')
            .select('*')
            .in('id', transformedEvent.djs);

          if (djsError) throw djsError;

          const transformedDJs: DJ[] = djsData.map(dj => ({
            id: dj.id,
            name: dj.name,
            profileImage: dj.profile_image,
            style: dj.style,
            socialMedia: dj.social_media,
            bio: dj.bio
          }));

          setDjs(transformedDJs);
        }

        setError(null);
      } catch (err) {
        setError((err as Error).message);
        console.error('Error fetching event details:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchEventAndDJs();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-white">Loading event details...</div>
        </div>
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="min-h-screen bg-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-red-400">
            {error || 'Event not found'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="h-[40vh] relative">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ 
            backgroundImage: `url(${event.image})`
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black" />
        
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-end pb-8">
          <div className="w-full">
            <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-6">
              <div>
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                  {event.name}
                </h1>
                <div className="flex flex-wrap gap-6 text-gray-300">
                  <div className="flex items-center">
                    <Calendar className="h-5 w-5 mr-2" />
                    {format(parseISO(event.date), 'PPP')}
                  </div>
                  <div className="flex items-center">
                    <Clock className="h-5 w-5 mr-2" />
                    {format(parseISO(event.date), 'h:mm a')}
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 mr-2" />
                    {event.isSecretLocation && !locationRevealed ? (
                      <button
                        onClick={() => setShowRSVPModal(true)}
                        className="flex items-center text-purple-400 hover:text-purple-300 transition"
                      >
                        <Lock className="h-4 w-4 mr-1" />
                        Secret Location (RSVP to reveal)
                      </button>
                    ) : (
                      event.location
                    )}
                  </div>
                  <div className="flex items-center">
                    <Ticket className="h-5 w-5 mr-2" />
                    {event.ticketed ? `$${event.price}` : 'Free Entry'}
                  </div>
                </div>
              </div>

              {event.ticketed && (
                <button
                  onClick={() => setShowTicketModal(true)}
                  className="btn btn-primary flex items-center relative z-10"
                >
                  <CreditCard className="h-5 w-5 mr-2" />
                  Buy Tickets
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-black to-transparent" />
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Event Description */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-4">About This Event</h2>
          <p className="text-gray-300 whitespace-pre-wrap">{event.description}</p>
        </div>

        {/* Timeline */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-6 flex items-center">
            <Clock className="h-6 w-6 mr-2" />
            Event Schedule
          </h2>
          <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
            <TimelineView
              eventDate={event.date}
              duration={event.duration}
              timeSlots={event.timeSlots}
              djs={djs}
            />
          </div>
        </div>

        {/* DJ Lineup */}
        <div>
          <h2 className="text-2xl font-bold text-white mb-6 flex items-center">
            <Music className="h-6 w-6 mr-2" />
            Featured Artists
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {djs.map(dj => (
              <div 
                key={dj.id}
                className="bg-white/10 backdrop-blur-sm rounded-lg overflow-hidden hover:bg-white/20 transition"
              >
                <div className="p-6">
                  <div className="flex items-center gap-4 mb-4">
                    <img 
                      src={dj.profileImage} 
                      alt={dj.name}
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <div>
                      <h3 className="text-xl font-bold text-white">{dj.name}</h3>
                      <p className="text-purple-400">{dj.style.join(' · ')}</p>
                    </div>
                  </div>

                  <p className="text-gray-400 text-sm line-clamp-3 mb-4">{dj.bio}</p>

                  {/* Social Media Links */}
                  <div className="flex gap-4">
                    {dj.socialMedia.instagram && (
                      <a 
                        href={`https://instagram.com/${dj.socialMedia.instagram.replace('@', '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-400 hover:text-purple-300 transition"
                      >
                        Instagram
                      </a>
                    )}
                    {dj.socialMedia.soundcloud && (
                      <a 
                        href={`https://soundcloud.com/${dj.socialMedia.soundcloud}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-400 hover:text-purple-300 transition"
                      >
                        SoundCloud
                      </a>
                    )}
                    {dj.socialMedia.twitter && (
                      <a 
                        href={`https://twitter.com/${dj.socialMedia.twitter.replace('@', '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-400 hover:text-purple-300 transition"
                      >
                        Twitter
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modals */}
      {showRSVPModal && (
        <RSVPModal
          event={event}
          onClose={() => setShowRSVPModal(false)}
          onVerified={() => {
            setLocationRevealed(true);
            setShowRSVPModal(false);
          }}
        />
      )}

      {showTicketModal && (
        <TicketPurchaseModal
          event={event}
          onClose={() => setShowTicketModal(false)}
        />
      )}
    </div>
  );
}