import { useMemo, useEffect } from 'react';
import { format, parseISO, addMinutes } from 'date-fns';
import { DJ } from '../types';

interface TimeSlotEditorProps {
  eventDate: string;
  duration: number;
  selectedDJs: DJ[];
  timeSlots: Array<{
    djId: string;
    startTime: string;
    endTime: string;
  }>;
  onChange: (timeSlots: Array<{
    djId: string;
    startTime: string;
    endTime: string;
  }>) => void;
}

export default function TimeSlotEditor({
  eventDate,
  duration,
  selectedDJs,
  timeSlots,
  onChange
}: TimeSlotEditorProps) {
  // Generate time options in 30-minute intervals
  const timeOptions = useMemo(() => {
    try {
      if (!eventDate) return [];
      
      const startTime = parseISO(eventDate);
      if (isNaN(startTime.getTime())) return [];
      
      const options = [];
      
      // Generate slots for the event duration
      for (let i = 0; i <= duration * 2; i++) {
        const time = addMinutes(startTime, i * 30);
        options.push({
          value: format(time, 'HH:mm'),
          label: format(time, 'h:mm a')
        });
      }
      
      return options;
    } catch (error) {
      console.error('Error generating time options:', error);
      return [];
    }
  }, [eventDate, duration]);

  // Initialize slots for new DJs with evenly distributed time slots
  useEffect(() => {
    if (timeOptions.length === 0) return;

    const unscheduledDJs = selectedDJs.filter(
      dj => !timeSlots.some(slot => slot.djId === dj.id)
    );

    if (unscheduledDJs.length > 0) {
      // Calculate slots per DJ
      const slotsPerDJ = Math.floor((timeOptions.length - 1) / unscheduledDJs.length);
      
      const newSlots = unscheduledDJs.map((dj, index) => {
        const startIndex = index * slotsPerDJ;
        const endIndex = Math.min(startIndex + slotsPerDJ, timeOptions.length - 1);
        
        return {
          djId: dj.id,
          startTime: timeOptions[startIndex].value,
          endTime: timeOptions[endIndex].value
        };
      });

      onChange([...timeSlots, ...newSlots]);
    }
  }, [selectedDJs.map(dj => dj.id).join(','), timeOptions.length]);

  if (timeOptions.length === 0) {
    return (
      <div className="text-gray-400 text-center p-4">
        Please set the event date, start time, and duration first.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {selectedDJs.map(dj => {
        const slot = timeSlots.find(s => s.djId === dj.id);
        if (!slot) return null;

        // Convert time format if needed (HH:mm:ss -> HH:mm)
        const normalizeTime = (time: string) => time.split(':').slice(0, 2).join(':');
        const startTime = normalizeTime(slot.startTime);
        const endTime = normalizeTime(slot.endTime);

        return (
          <div key={dj.id} className="bg-white/10 rounded-lg p-4">
            <div className="flex items-center gap-4 mb-4">
              <img 
                src={dj.profileImage} 
                alt={dj.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div>
                <h4 className="text-lg font-semibold text-white">{dj.name}</h4>
                <p className="text-sm text-gray-400">{dj.style.join(' · ')}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-gray-400 mb-1">Start Time</label>
                <select
                  value={startTime}
                  onChange={(e) => {
                    const newSlots = timeSlots.map(s => 
                      s.djId === dj.id ? { ...s, startTime: e.target.value } : s
                    );
                    onChange(newSlots);
                  }}
                  className="input bg-white/10 text-white border-gray-700"
                >
                  {timeOptions.map(option => (
                    <option 
                      key={option.value} 
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">End Time</label>
                <select
                  value={endTime}
                  onChange={(e) => {
                    const newSlots = timeSlots.map(s => 
                      s.djId === dj.id ? { ...s, endTime: e.target.value } : s
                    );
                    onChange(newSlots);
                  }}
                  className="input bg-white/10 text-white border-gray-700"
                >
                  {timeOptions.map(option => (
                    <option 
                      key={option.value} 
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}