import { useState } from 'react';
import { Menu, Music2, X, Ticket } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../services/auth';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = auth.isAuthenticated();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLogout = () => {
    auth.logout();
    navigate('/');
  };

  const handleLogoDoubleClick = () => {
    navigate('/admin/login');
  };

  return (
    <header className="fixed top-0 w-full bg-black/80 backdrop-blur-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link 
            to="/" 
            className="flex items-center space-x-2"
            onDoubleClick={handleLogoDoubleClick}
          >
            <Music2 className="h-8 w-8 text-purple-500" />
            <span className="text-2xl font-bold bg-gradient-to-r from-purple-500 to-pink-500 text-transparent bg-clip-text">
              RaveSurge
            </span>
          </Link>
          
          <nav className="hidden md:flex items-center space-x-8">
            <Link to="/events" className="text-white hover:text-purple-400 transition">
              Events
            </Link>
            {isAuthenticated && (
              <>
                <Link to="/admin/events" className="text-white hover:text-purple-400 transition">
                  Manage Events
                </Link>
                <Link to="/admin/djs" className="text-white hover:text-purple-400 transition">
                  Manage DJs
                </Link>
                <Link to="/admin/tickets" className="text-white hover:text-purple-400 transition">
                  <span className="flex items-center">
                    <Ticket className="h-4 w-4 mr-1" />
                    Tickets
                  </span>
                </Link>
                <Link to="/security/scanner" className="text-white hover:text-purple-400 transition">
                  Scanner
                </Link>
                <button
                  onClick={handleLogout}
                  className="text-white hover:text-purple-400 transition"
                >
                  Logout
                </button>
              </>
            )}
          </nav>

          <button 
            onClick={toggleMenu}
            className="md:hidden text-white p-2 hover:bg-white/10 rounded-lg transition"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4 border-t border-white/10">
            <nav className="flex flex-col space-y-4">
              <Link 
                to="/events" 
                className="text-white hover:text-purple-400 transition px-2 py-1"
                onClick={() => setIsMenuOpen(false)}
              >
                Events
              </Link>
              {isAuthenticated && (
                <>
                  <Link 
                    to="/admin/events" 
                    className="text-white hover:text-purple-400 transition px-2 py-1"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Manage Events
                  </Link>
                  <Link 
                    to="/admin/djs" 
                    className="text-white hover:text-purple-400 transition px-2 py-1"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Manage DJs
                  </Link>
                  <Link 
                    to="/admin/tickets" 
                    className="text-white hover:text-purple-400 transition px-2 py-1"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <span className="flex items-center">
                      <Ticket className="h-4 w-4 mr-1" />
                      Tickets
                    </span>
                  </Link>
                  <Link 
                    to="/security/scanner" 
                    className="text-white hover:text-purple-400 transition px-2 py-1"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Scanner
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsMenuOpen(false);
                    }}
                    className="text-white hover:text-purple-400 transition px-2 py-1 text-left"
                  >
                    Logout
                  </button>
                </>
              )}
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}