import { X } from 'lucide-react';
import { Ticket } from '../../types';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';

interface QRCodeModalProps {
  ticket: Ticket;
  onClose: () => void;
}

export default function QRCodeModal({ ticket, onClose }: QRCodeModalProps) {
  const [qrDataUrl, setQrDataUrl] = useState<string>('');

  useEffect(() => {
    const generateQR = async () => {
      try {
        // Create a ticket payload that matches what we'll scan
        const ticketPayload = JSON.stringify({
          ticketId: ticket.id,
          customerEmail: ticket.customerEmail
        });

        const dataUrl = await QRCode.toDataURL(ticketPayload, {
          width: 300,
          margin: 2,
          errorCorrectionLevel: 'H',
          color: {
            dark: '#000000',  // Changed to black for better contrast
            light: '#ffffff'  // Changed to white for better contrast
          }
        });
        setQrDataUrl(dataUrl);
      } catch (err) {
        console.error('Error generating QR code:', err);
      }
    };

    generateQR();
  }, [ticket]);

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg w-full max-w-md p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="text-center">
          <h3 className="text-xl font-bold text-white mb-4">Ticket QR Code</h3>
          
          <div className="bg-white rounded-lg p-6 mb-4">
            {qrDataUrl && (
              <img 
                src={qrDataUrl} 
                alt="Ticket QR Code"
                className="mx-auto"
              />
            )}
          </div>

          <div className="text-left space-y-2 mb-4">
            <p className="text-gray-400">
              <span className="font-medium text-white">Customer:</span> {ticket.customerName}
            </p>
            <p className="text-gray-400">
              <span className="font-medium text-white">Email:</span> {ticket.customerEmail}
            </p>
            <p className="text-gray-400">
              <span className="font-medium text-white">Status:</span>{' '}
              <span className={`${
                ticket.status === 'valid' ? 'text-green-400' :
                ticket.status === 'used' ? 'text-blue-400' :
                'text-red-400'
              }`}>
                {ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}
              </span>
            </p>
          </div>

          <p className="text-sm text-gray-500">
            Ticket ID: {ticket.id}
          </p>
        </div>
      </div>
    </div>
  );
}