import { create } from 'zustand';
import { DJ } from '../types';
import { api } from '../services/api';

interface DJStore {
  djs: DJ[];
  loading: boolean;
  error: string | null;
  fetchDJs: () => Promise<void>;
  addDJ: (dj: Omit<DJ, 'id'>) => Promise<void>;
  updateDJ: (id: string, dj: Partial<DJ>) => Promise<void>;
  deleteDJ: (id: string) => Promise<void>;
}

export const useDJStore = create<DJStore>((set) => ({
  djs: [],
  loading: false,
  error: null,
  fetchDJs: async () => {
    set({ loading: true, error: null });
    try {
      const djs = await api.getDJs();
      set({ djs, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      console.error('Failed to fetch DJs:', error);
    }
  },
  addDJ: async (dj) => {
    set({ loading: true, error: null });
    try {
      const newDJ = await api.createDJ(dj);
      set((state) => ({ 
        djs: [...state.djs, newDJ],
        loading: false 
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
  updateDJ: async (id, dj) => {
    set({ loading: true, error: null });
    try {
      const updatedDJ = await api.updateDJ(id, dj);
      set((state) => ({
        djs: state.djs.map((d) => (d.id === id ? updatedDJ : d)),
        loading: false
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
  deleteDJ: async (id) => {
    set({ loading: true, error: null });
    try {
      await api.deleteDJ(id);
      set((state) => ({
        djs: state.djs.filter((d) => d.id !== id),
        loading: false
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
      throw error;
    }
  },
}));