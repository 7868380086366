import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Events from './pages/Events';
import EventDetails from './pages/EventDetails';
import EventList from './pages/admin/EventList';
import EventEditor from './pages/admin/EventEditor';
import DJList from './pages/admin/DJList';
import DJEditor from './pages/admin/DJEditor';
import TicketList from './pages/admin/TicketList';
import TicketScanner from './pages/security/TicketScanner';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router basename="/">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<EventDetails />} />
        <Route path="/admin/login" element={<Login />} />
        <Route
          path="/admin/events"
          element={
            <ProtectedRoute>
              <EventList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/events/new"
          element={
            <ProtectedRoute>
              <EventEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/events/:id"
          element={
            <ProtectedRoute>
              <EventEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/djs"
          element={
            <ProtectedRoute>
              <DJList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/djs/new"
          element={
            <ProtectedRoute>
              <DJEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/djs/:id"
          element={
            <ProtectedRoute>
              <DJEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/tickets"
          element={
            <ProtectedRoute>
              <TicketList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/security/scanner"
          element={
            <ProtectedRoute>
              <TicketScanner />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;