import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Mail, User, Users } from 'lucide-react';
import { Event, TicketPurchase } from '../../types';
import { tickets } from '../../services/tickets';

interface TicketPurchaseFormProps {
  event: Event;
  onSuccess: () => void;
}

export default function TicketPurchaseForm({ event, onSuccess }: TicketPurchaseFormProps) {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, formState: { errors }, watch } = useForm<TicketPurchase>({
    defaultValues: {
      eventId: event.id,
      quantity: 1
    }
  });

  const quantity = watch('quantity');
  const totalPrice = event.price ? event.price * quantity : 0;

  const onSubmit = async (data: TicketPurchase) => {
    try {
      setProcessing(true);
      setError(null);
      
      // Create ticket in demo mode
      await tickets.purchaseTicket(data);
      
      // Show success message
      alert('Demo ticket created successfully! In production, this would process payment and generate a real ticket.');
      onSuccess();
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <h3 className="text-xl font-bold text-white mb-4">Purchase Tickets</h3>
        
        <div className="bg-white/10 rounded-lg p-4 mb-6">
          <h4 className="font-semibold text-white mb-2">{event.name}</h4>
          <p className="text-gray-300">Price per ticket: ${event.price}</p>
        </div>

        <div className="mb-4">
          <label className="block text-white mb-2">
            <span className="flex items-center">
              <Users className="w-4 h-4 mr-2" />
              Number of Tickets
            </span>
          </label>
          <select
            {...register('quantity', { 
              required: 'Please select quantity',
              min: { value: 1, message: 'Minimum 1 ticket' }
            })}
            className="input bg-white/10 text-white border-gray-700"
            disabled={processing}
          >
            {[1, 2, 3, 4, 5].map(num => (
              <option key={num} value={num}>
                {num} {num === 1 ? 'ticket' : 'tickets'}
              </option>
            ))}
          </select>
          {errors.quantity && (
            <p className="text-red-400 text-sm mt-1">{errors.quantity.message}</p>
          )}
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-white mb-2">
              <span className="flex items-center">
                <User className="w-4 h-4 mr-2" />
                Full Name
              </span>
            </label>
            <input
              {...register('customerName', { required: 'Name is required' })}
              className="input bg-white/10 text-white border-gray-700"
              placeholder="Enter your full name"
              disabled={processing}
            />
            {errors.customerName && (
              <p className="text-red-400 text-sm mt-1">{errors.customerName.message}</p>
            )}
          </div>

          <div>
            <label className="block text-white mb-2">
              <span className="flex items-center">
                <Mail className="w-4 h-4 mr-2" />
                Email Address
              </span>
            </label>
            <input
              {...register('customerEmail', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              })}
              type="email"
              className="input bg-white/10 text-white border-gray-700"
              placeholder="Enter your email"
              disabled={processing}
            />
            {errors.customerEmail && (
              <p className="text-red-400 text-sm mt-1">{errors.customerEmail.message}</p>
            )}
          </div>
        </div>

        <div className="mt-6 p-4 bg-purple-500/20 rounded-lg">
          <div className="flex justify-between items-center text-white">
            <span className="font-semibold">Total Price:</span>
            <span className="text-xl font-bold">${totalPrice.toFixed(2)}</span>
          </div>
          <p className="text-sm text-gray-400 mt-2">Demo Mode: No actual payment will be processed</p>
        </div>

        {error && (
          <div className="mt-4 p-4 bg-red-500/20 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        <button
          type="submit"
          disabled={processing}
          className="mt-6 w-full btn btn-primary flex items-center justify-center"
        >
          {processing ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : (
            <span>Create Demo Ticket</span>
          )}
        </button>
      </div>
    </form>
  );
}