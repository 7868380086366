import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Edit, Trash, Download } from 'lucide-react';
import { useEventStore } from '../../store/eventStore';
import { format, parseISO } from 'date-fns';
import { useDJStore } from '../../store/djStore';

export default function EventList() {
  const { events, fetchEvents, deleteEvent } = useEventStore();
  const { djs, fetchDJs } = useDJStore();

  useEffect(() => {
    fetchEvents();
    fetchDJs();
  }, [fetchEvents, fetchDJs]);

  const formatEventForExport = (event) => {
    const eventDJs = djs.filter(dj => event.djs.includes(dj.id));
    return {
      name: event.name,
      date: format(parseISO(event.date), 'PPP'),
      time: format(parseISO(event.date), 'h:mm a'),
      location: event.location,
      djs: eventDJs.map(dj => ({
        name: dj.name,
        styles: dj.style
      }))
    };
  };

  const handleExportJSON = async (event = null) => {
    const exportData = event 
      ? formatEventForExport(event)
      : events.map(formatEventForExport);

    const fileName = event 
      ? `event-${event.name.toLowerCase().replace(/\s+/g, '-')}-${format(new Date(), 'yyyy-MM-dd')}.json`
      : `all-events-${format(new Date(), 'yyyy-MM-dd')}.json`;

    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold text-white">Manage Events</h1>
          <div className="flex gap-4">
            <button
              onClick={() => handleExportJSON()}
              className="btn btn-primary inline-flex items-center bg-indigo-600 hover:bg-indigo-700"
            >
              <Download className="h-5 w-5 mr-2" />
              Export All Events
            </button>
            <Link 
              to="/admin/events/new" 
              className="btn btn-primary inline-flex items-center"
            >
              <Plus className="h-5 w-5 mr-2" />
              New Event
            </Link>
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden space-y-4">
          {events.map(event => (
            <div 
              key={event.id} 
              className="bg-white/10 rounded-lg overflow-hidden p-4"
            >
              <div className="flex justify-between items-start mb-3">
                <h3 className="text-lg font-semibold text-white">{event.name}</h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleExportJSON(event)}
                    className="p-2 text-gray-400 hover:text-indigo-400 transition"
                    title="Export event"
                  >
                    <Download className="h-5 w-5" />
                  </button>
                  <Link
                    to={`/admin/events/${event.id}`}
                    className="p-2 text-gray-400 hover:text-white transition"
                  >
                    <Edit className="h-5 w-5" />
                  </Link>
                  <button
                    onClick={() => deleteEvent(event.id)}
                    className="p-2 text-gray-400 hover:text-red-500 transition"
                  >
                    <Trash className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="space-y-2 text-sm">
                <p className="text-gray-300">
                  {format(new Date(event.date), 'PPP')}
                </p>
                <p className="text-gray-300">{event.location}</p>
                <span className={`inline-block px-2 py-1 rounded-full text-xs ${
                  event.ticketed ? 'bg-green-500/20 text-green-300' : 'bg-blue-500/20 text-blue-300'
                }`}>
                  {event.ticketed ? 'Ticketed' : 'Free'}
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop view */}
        <div className="hidden md:block bg-white/10 rounded-lg overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="px-6 py-3 text-left text-white">Name</th>
                <th className="px-6 py-3 text-left text-white">Date</th>
                <th className="px-6 py-3 text-left text-white">Location</th>
                <th className="px-6 py-3 text-left text-white">Status</th>
                <th className="px-6 py-3 text-right text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event.id} className="border-b border-gray-700">
                  <td className="px-6 py-4 text-white">{event.name}</td>
                  <td className="px-6 py-4 text-white">
                    {format(new Date(event.date), 'PPP')}
                  </td>
                  <td className="px-6 py-4 text-white">{event.location}</td>
                  <td className="px-6 py-4">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      event.ticketed ? 'bg-green-500/20 text-green-300' : 'bg-blue-500/20 text-blue-300'
                    }`}>
                      {event.ticketed ? 'Ticketed' : 'Free'}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => handleExportJSON(event)}
                        className="p-2 text-gray-400 hover:text-indigo-400 transition"
                        title="Export event"
                      >
                        <Download className="h-5 w-5" />
                      </button>
                      <Link
                        to={`/admin/events/${event.id}`}
                        className="p-2 text-gray-400 hover:text-white transition"
                      >
                        <Edit className="h-5 w-5" />
                      </Link>
                      <button
                        onClick={() => deleteEvent(event.id)}
                        className="p-2 text-gray-400 hover:text-red-500 transition"
                      >
                        <Trash className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}