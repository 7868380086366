import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Edit, Trash } from 'lucide-react';
import { useDJStore } from '../../store/djStore';

export default function DJList() {
  const { djs, fetchDJs, deleteDJ } = useDJStore();

  useEffect(() => {
    fetchDJs();
  }, [fetchDJs]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 via-black to-black pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold text-white">Manage DJs</h1>
          <Link 
            to="/admin/djs/new" 
            className="btn btn-primary inline-flex items-center"
          >
            <Plus className="h-5 w-5 mr-2" />
            New DJ
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {djs.map(dj => (
            <div key={dj.id} className="bg-white/10 rounded-lg overflow-hidden">
              <img 
                src={dj.profileImage} 
                alt={dj.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-white mb-2">{dj.name}</h3>
                <p className="text-gray-300 text-sm mb-4">{dj.style.join(', ')}</p>
                <div className="flex justify-between items-center">
                  <Link
                    to={`/admin/djs/${dj.id}`}
                    className="btn btn-primary"
                  >
                    <Edit className="h-4 w-4 mr-2" />
                    Edit
                  </Link>
                  <button
                    onClick={() => deleteDJ(dj.id)}
                    className="p-2 text-gray-400 hover:text-red-500 transition"
                  >
                    <Trash className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}